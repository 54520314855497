export enum PackageType {
  FREE = "FREE",
  PREMIUM = "PREMIUM",
  PRO = "PRO",
  ENTERPRISE = "ENTERPRISE",
  ONE_TIME_T1 = "ONE_TIME_T1",
  ONE_TIME_T2 = "ONE_TIME_T2",
  ONE_TIME_T3 = "ONE_TIME_T3",
}

export enum PricingPlan {
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export interface PackagePrice {
  created_at: string;
  currency: "$";
  deleted_at: null;
  id: string;
  package_id: string;
  plan: PricingPlan;
  price: number;
  stripe_price_id: string;
  updated_at: string;
}

export interface Package {
  id: string;
  name: string;
  currency: string;
  price: number;
  chatbot_number?: number;
  message_number?: number;
  type: PackageType;
  chatbot_data_limit: number;
  max_monthly_messages?: number;
  max_monthly_credit?: number;
  max_member?: number;
  max_bot?: number;
  max_bot_data?: number;
  prices: PackagePrice[];
  order: number;
}
